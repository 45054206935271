import React from 'react';


const Signin = React.lazy(() => import('./Components/Authentication/SignIn/SignIn1'));
const ResetPassword1 = React.lazy(() => import('./Components/Authentication/ResetPassword/ResetPassword1'));

const route = [
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin },
    { path: '/auth/reset-password-1', exact: true, name: 'Reset Password 1', component: ResetPassword1 },
];

export default route;